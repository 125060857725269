import {action, observable} from "mobx";

class OrderListStore {
    @observable _list = [];
    @observable _pageSize = 10;
    @observable _pageNum = 1;
    @observable _totalNum = 0;
    @observable _currentStatus = undefined;

    get list() {
        return this._list;
    }

    set list(value) {
        this._list = value;
    }

    get pageSize() {
        return this._pageSize;
    }

    set pageSize(value) {
        this._pageSize = value;
    }

    get pageNum() {
        return this._pageNum;
    }

    set pageNum(value) {
        this._pageNum = value;
    }

    get currentStatus() {
        return this._currentStatus;
    }

    set currentStatus(value) {
        this._currentStatus = value;
    }

    get totalNum() {
        return this._totalNum;
    }

    set totalNum(value) {
        this._totalNum = value;
    }
}

export default new OrderListStore();
import { observable } from 'mobx';

class Register {
  @observable _username = '';
  @observable _password = '';
  @observable _password2 = '';
  @observable _verify = '';

  get username() {
    return this._username;
  }
  set username(value) {
    this._username = value;
  }
  get password() {
    return this._password;
  }
  set password(value) {
    this._password = value;
  }
  get password2() {
    return this._password2;
  }
  set password2(value) {
    this._password2 = value;
  }
  get verify() {
    return this._verify;
  }
  set verify(value) {
    this._verify = value;
  }
}

const register = new Register();

export default register;

import {action, observable} from "mobx";

class OrderPayStore {
    @observable _id = 0;
    @observable _sn = '';
    @observable _price = 0;
    @observable _status = undefined;
    @observable _createTime = 0;
    @observable _delivery = undefined;
    @observable _orderAddress = undefined;
    @observable _orderProductList = [];
    @observable _payTypeList = [];
    @observable _currentPayTypeId = 0;
    @observable _showAddAddress = false;
    @observable _showModifyAddress = false;
    @observable _addressObj = {
        list:[],
        isOpen:false,
        currentAddress:undefined
    }
    @observable _qrCodeUrl = '';
    @observable _showQrCode = false;

    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    get sn() {
        return this._sn;
    }

    set sn(value) {
        this._sn = value;
    }

    get price() {
        return this._price;
    }

    set price(value) {
        this._price = value;
    }

    get status() {
        return this._status;
    }

    set status(value) {
        this._status = value;
    }

    get createTime() {
        return this._createTime;
    }

    set createTime(value) {
        this._createTime = value;
    }

    get delivery() {
        return this._delivery;
    }

    set delivery(value) {
        this._delivery = value;
    }

    get orderAddress() {
        return this._orderAddress;
    }

    set orderAddress(value) {
        this._orderAddress = value;
    }

    get orderProductList() {
        return this._orderProductList;
    }

    set orderProductList(value) {
        this._orderProductList = value;
    }

    get payTypeList() {
        return this._payTypeList;
    }

    set payTypeList(value) {
        this._payTypeList = value;
    }

    get currentPayTypeId() {
        return this._currentPayTypeId;
    }

    set currentPayTypeId(value) {
        this._currentPayTypeId = value;
    }

    get showAddAddress() {
        return this._showAddAddress;
    }

    set showAddAddress(value) {
        this._showAddAddress = value;
    }

    get showModifyAddress() {
        return this._showModifyAddress;
    }

    set showModifyAddress(value) {
        this._showModifyAddress = value;
    }

    get addressObj() {
        return this._addressObj;
    }

    set addressObj(value) {
        this._addressObj = value;
    }

    get qrCodeUrl() {
        return this._qrCodeUrl;
    }

    set qrCodeUrl(value) {
        this._qrCodeUrl = value;
    }

    get showQrCode() {
        return this._showQrCode;
    }

    set showQrCode(value) {
        this._showQrCode = value;
    }
}

export default new OrderPayStore();
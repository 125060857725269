import { observable } from 'mobx';

class Menu {
  @observable _is1200 = true;

  get is1200() {
    return this._is1200;
  }
  set is1200(value) {
    this._is1200 = value;
  }
}

const menu = new Menu();

export default menu;

import { observable } from 'mobx';

class Login {
  @observable _username = '';
  @observable _password = '';
  @observable _show = false;

  get username() {
    return this._username;
  }
  set username(value) {
    this._username = value;
  }
  get password() {
    return this._password;
  }
  set password(value) {
    this._password = value;
  }
  get show() {
    return this._show;
  }
  set show(value) {
    this._show = value;
  }
}

const login = new Login();

export default login;

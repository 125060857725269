import { observable } from 'mobx';

class GoodsList {
  @observable _show = false;
  @observable _goodsList = [
    {
      id: 1,
      name: '商品名称1',
      currentPrice: 2300,
      originPrice: 3000,
      count: 10,
      img: '',
      specificationMap: new Map([
        ['重量', '3g'],
        ['颜色', '白色']
      ])
    },
    {
      id: 2,
      name: '商品名称2',
      currentPrice: 2300,
      originPrice: 3000,
      count: 10,
      img: '',
      specificationMap: new Map([
        ['重量', '4g'],
        ['颜色', '红色']
      ])
    }
  ];

  get show() {
    return this._show;
  }
  set show(value) {
    this._show = value;
  }
  get goodsList() {
    return this._goodsList;
  }
  set goodsList(value) {
    this._goodsList = value;
  }
}

const goodsList = new GoodsList();

export default goodsList;

import {observable} from "mobx";

class AddressListStore {
    @observable _list = [];
    @observable _showAddAddress = false;
    @observable _showModifyAddress = false;
    @observable _currentAddress = undefined;

    get list() {
        return this._list;
    }

    set list(value) {
        this._list = value;
    }

    get showAddAddress() {
        return this._showAddAddress;
    }

    set showAddAddress(value) {
        this._showAddAddress = value;
    }

    get showModifyAddress() {
        return this._showModifyAddress;
    }

    set showModifyAddress(value) {
        this._showModifyAddress = value;
    }

    get currentAddress() {
        return this._currentAddress;
    }

    set currentAddress(value) {
        this._currentAddress = value;
    }
}

const store = new AddressListStore()

export default store

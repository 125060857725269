import {action, observable} from "mobx";
import * as Api from "api/api";
import ImgEnum from 'enum/ImgEnum';

class HomeStore {
    @observable _secKillGoodsList = [];
    @observable _selfFloor = null;
    @observable _goodsFloorList = [];
    @observable _guessList = [];
    @observable _lookAroundList = [];
    @observable _newsList = [];
    @observable _showTopAdvertisement = true;
    @observable _canShowTopAdvertisement = true;
    @observable _bigBanner = [];
    @observable _categoryNav = [];

    @action
    async fetchSecKillGoodsList() {
        const {list} = await Api.homeSecKillGoodsList({
            pageSize: 12,
            pageNum: 1
        });
        this._secKillGoodsList = list;
    }

    @action
    async fetchSelfFloor(hasSelf) {
        let {selfFloor} = await Api.selfFloor({
            pageNum: 1,
            pageSize: 16
        });

        if (!hasSelf) {
            let {list} = await Api.recommendList({
                pageSize: 12,
                pageNum: 1
            });

            list = list.splice(0, 12);

            selfFloor = {...selfFloor, hotGoodsList: list};
        }
        this._selfFloor = selfFloor
    }

    @action
    async fetchGuessList() {
        let {list} = await Api.recommendList({pageSize: 10, pageNum: 1});

        list = list.splice(0, 10);
        this._guessList = list;
    }

    @action
    fetchLookAroundList({pageSize, pageNum}) {

        Api.recommendList({pageSize, pageNum}).then(({list}) => {

            let i_5 = Math.floor(list.length / 5);
            list = list.slice(0, i_5 * 5);


            if (pageNum === 1) {
                this._lookAroundList = list;
            } else if (pageNum > 1) {
                this._lookAroundList = [...this._lookAroundList, ...list]
            }
        })

    }

    @action
    async fetchNewsList() {
        const {list, totalNum} = await Api.homeNewsList({
            pageSize: 15,
            pageNum: 1
        });
        this._newsList = list;
    }

    @action
    async fetchBigBanner() {
        const {list} = await Api.homeBigBanner({pageSize: 5, pageNum: 1});
        this._bigBanner = list;
    }

    @action
    async fetchCategoryNav() {
        let {list} = await Api.imgList({type: ImgEnum.SPECIAL});

        const {
            hasSelfCategory
          } = await Api.pddGoodsCategoryListByParentId({
            parentId: 0,
            parentOptId:0
          });

        list = await Promise.all(list.map(async imgInfo => {
            const {id, imgUrl: img, title: name,optKeyId} = imgInfo;

            const opt = await Api.pddOpt(optKeyId);

            return {
                id,
                img,
                name,
                opt:{
                    ...opt,
                    hasSelf:hasSelfCategory
                }
            };
        }));
        

        console.log('list:%o',[...list])

        if (list.length < 7) {
            for (let i = 0; i < 7 - list.length; i++) {
                list.push({
                    id: 0 - (i + 1),
                    name: '',
                    img: ''
                });
            }
        }

        this._categoryNav = list;
    }

    @action
    modifyGoodsFloorList({id, value}) {
        let goodsFloorList = [...this._goodsFloorList]

        for (let i = 0; i < goodsFloorList.length; i++) {
            if (goodsFloorList[i].id === id) {
                goodsFloorList[i] = {...goodsFloorList[i], ...value};
            }
        }

        this._goodsFloorList = goodsFloorList;
    }

    get secKillGoodsList() {
        return this._secKillGoodsList;
    }

    set secKillGoodsList(value) {
        this._secKillGoodsList = value;
    }

    get selfFloor() {
        return this._selfFloor;
    }

    set selfFloor(value) {
        this._selfFloor = value;
    }

    get goodsFloorList() {
        return this._goodsFloorList;
    }

    set goodsFloorList(value) {
        this._goodsFloorList = value;
    }

    get guessList() {
        return this._guessList;
    }

    set guessList(value) {
        this._guessList = value;
    }

    get lookAroundList() {
        return this._lookAroundList;
    }

    set lookAroundList(value) {
        this._lookAroundList = value;
    }

    get newsList() {
        return this._newsList;
    }

    set newsList(value) {
        this._newsList = value;
    }

    get showTopAdvertisement() {
        return this._showTopAdvertisement;
    }

    set showTopAdvertisement(value) {
        this._showTopAdvertisement = value;
    }

    get canShowTopAdvertisement() {
        return this._canShowTopAdvertisement;
    }

    set canShowTopAdvertisement(value) {
        this._canShowTopAdvertisement = value;
    }

    get bigBanner() {
        return this._bigBanner;
    }

    set bigBanner(value) {
        this._bigBanner = value;
    }

    get categoryNav() {
        return this._categoryNav;
    }

    set categoryNav(value) {
        this._categoryNav = value;
    }
}

const store = new HomeStore();
export default store;
import { observable, action } from 'mobx';
import * as Api from 'api/api';

class QrCode {
  @observable _list = [];
  
  get list() {
    return this._list;
  }
  set list(value) {
    this._list = value;
  }

  @action async fetchList() {
    try {
      const { list } = await Api.qrCodeList();

      this._list = list;
    } catch (error) {}
  }
}

const qrCode = new QrCode();
export default qrCode;

import {
  http,
  Header,
  ContentType,
  qs,
  GetFormData,
  StatusCode,
  Global,
  Method
} from "./api-util";

/**
 * 搜索
 * @param {*} param0
 */
export const search = async ({
  categoryId,
  isNew,
  isHot,
  keyword,
  order,
  sortFiled,
  fromPrice,
  toPrice,
  pageSize,
  pageNum,
  goodsType,
  isSpecialPrice
}) => {
  const { data } = await http({
    method: Method.POST,
    url: Global.BASE_URL + "/api/index/search",
    data: {
      categoryId,
      isNew,
      isHot,
      keyword: keyword && keyword.trim(),
      order,
      sortFiled,
      fromPrice,
      toPrice,
      page: pageNum,
      size: pageSize,
      goodsType,
      isSpecialPrice
    }
  });

  const list = data.msg.objectList.map(item => {
    const {
      id,
      name,
      originPrice,
      currentPrice,
      shopId,
      soldNum: saleNum,
      mainPic: img,
      hasCollect,
      collectId,
      categoryId,
      isHot,
      pddShop,
      mallName,
      serviceTags
    } = item;

    return {
      id,
      name,
      originPrice,
      currentPrice,
      saleNum,
      img,
      hasCollect,
      collectId,
      categoryId,
      isHot,
      pddShop,
      mallName,
      serviceTags
    };
  });

  const { totalNum } = data.msg;

  return { list, totalNum };
};

// 百度推广连接
export const recommendKeywordList = async ({ keyword }) => {
  //   const url = 'http://suggestion.baidu.com/su';
  const url = "http://suggestion.baidu.com";
  const res = await http({
    method: Method.GET,
    url,
    params: {
      wd: keyword,
      action: "opensearch"
    }
  });
  return res;
};

import {
    http,
    Header,
    ContentType,
    qs,
    GetFormData,
    StatusCode,
    Global,
    Method
} from './api-util';

export const pddOpt = (id)=>{
    return http({
        method:Method.GET,
        url:Global.BASE_URL + `/api/pdd/opt/${id}`
    }).then(res=>res.data)
}
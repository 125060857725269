import {
    http,
    Header,
    ContentType,
    qs,
    GetFormData,
    StatusCode,
    Global,
    Method
} from './api-util';

import ImgEnum from 'enum/ImgEnum';

export const floorList = () => {
    return http({
        method: Method.GET,
        url: Global.BASE_URL + '/api/index/floor',
    })
        .then(res => res.data)
        .then(floorList => floorList?.map(floor => {
            let originImgList = [...floor.imgList];
            let imgList = [];
            let rightDownImg = '';

            for (let j in originImgList) {
                const {type} = originImgList[j];

                if (type === ImgEnum.CATEGORY_RIGHT_IMG) {
                    rightDownImg = originImgList[j];
                } else if (type === ImgEnum.CATEGORY_IMG) {
                    imgList.push(originImgList[j]);
                }
            }

            floor.imgList = imgList;
            floor.rightDownImg = rightDownImg;

            return floor;
        }) || []);
}

export const floorInfo = ({id}) => {
    return http({
        method: Method.GET,
        url: Global.BASE_URL + `/api/index/floor/${id}`,
        params: {
            page: 1,
            size: 15
        }
    })
        .then(res => res.data)
        .catch(err => {
            if (err === '拼多多') {
                return Promise.resolve({goodsListDTOS: []})
            } else if (err === "网络错误") {
                return floorInfo({id});
            }
        })

}

/**
 * 自营楼层
 * @param {*} param0
 */
export const selfFloor = async ({pageNum, pageSize}) => {
    const {data} = await http({
        method: Method.POST,
        url: Global.BASE_URL + '/api/index/self/run/index',
        data: {
            page: pageNum,
            size: pageSize
        }
    });

    let {
        hotGoods: hotGoodsList,
        hotWord: hotWordList,
        selfRunImg: originImgList
    } = data;

    let imgList = [];
    let leftImgList = [];

    for (let i in originImgList) {
        const {type} = originImgList[i];

        if (
            type === ImgEnum.SELF_RUN_LEFT_IMG ||
            type === ImgEnum.SELF_RUN_LEFT_IMG2 ||
            type === ImgEnum.SELF_RUN_RIGHT_IMG1 ||
            type === ImgEnum.SELF_RUN_RIGHT_IMG2
        ) {
            imgList.push(originImgList[i]);
        } else if (type === ImgEnum.SELF_RUN_INDEX) {
            leftImgList.push(originImgList[i]);
        }
    }

    return {
        selfFloor: {
            hotGoodsList,
            hotWordList,
            imgList,
            leftImgList
        }
    };
};

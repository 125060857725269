import {action, computed, observable} from "mobx";
import * as Api from "api/api";
import Collect from "enum/Collect";

import GoodsType from "enum/GoodsType";

class GoodsInfoStore {
    @observable _goods = null;
    @observable _skuList = [];
    @observable _currentSku = null;
    @observable _hasCollect = false;
    @observable _collectId = 0;
    @observable _goodsAttributeList = [];
    @observable _specificationMapList = [];
    @observable _count = 1;
    @observable _recommendList = [];
    @observable _lookAroundList = [];
    @observable _promotionUrl = "";
    @observable _description = "";
    @observable _isSecKill = false;
    @observable _secKill = {
        hour: 0,
        minute: 0,
        second: 0
    }
    @observable _loading = false;

    @action
    async addCollect({id}) {
        const {collectId} = await Api.addCollect({id, type: Collect.GOODS});
        this._collectId = collectId;
        this._hasCollect = true;
    }

    @action
    async cancelCollect({collectId}) {
        await Api.cancelCollect({collectId});
        this._hasCollect = false;
        this._collectId = 0;
    }

    @action
    async fetchGoodsInfo({goodsId, pddShop}) {
        try {
            this._loading = true;

            let type = GoodsType.PDD;
            if (pddShop) {
                type = GoodsType.PDD;
            } else {
                type = GoodsType.SELF;
            }
            let {
                skuList,
                goods,
                hasCollect,
                collectId,
                goodsAttributeList,
                specificationMapList,
                goodsPromotionUrl
            } = await Api.goodsInfo({goodsId, type});


            let imgList = [];
            if (skuList && skuList.length) {

                let currentSku = skuList.find(sku => sku.id === goods.primaryProductId);

                /**
                 * 设置规格
                 */
                let selectedSpecificationIdList = currentSku.goodsSpecificationIds
                    .split("_")
                    .map(id => Number(id));

                let mapList = [];
                for (let key in specificationMapList) {
                    let specificationList = specificationMapList[key];

                    let map = {};
                    map.list = [...specificationList];
                    map.currentIndex = 0;

                    for (let i = 0; i < specificationList.length; i++) {
                        if (selectedSpecificationIdList.indexOf(specificationList[i].id) !== -1) {
                            map.currentIndex = i;
                            break;
                        }
                    }

                    mapList[key] = map;
                }

                this._skuList = skuList;
                this._currentSku = currentSku;
                this._specificationMapList = {...mapList};
                this._hasCollect = hasCollect;
                this._collectId = collectId;
            }


            if (goods.description) {
                this._description = goods.description;
            }
            if (goodsPromotionUrl) {
                this._promotionUrl = goodsPromotionUrl;
            }

            if (goods) {
                this._goods = goods;
            }
            if (goodsAttributeList) {
                this._goodsAttributeList = goodsAttributeList;
            }
        } catch (error) {
        } finally {
            this._loading = false;
        }
    }

    @action
    selectSpecification({key, index}) {
        let specificationMapList = {...this._specificationMapList};
        specificationMapList[key].currentIndex = index;
        this._specificationMapList = specificationMapList;

        let currentSpecificationIdList = [];
        for (let key in specificationMapList) {
            let value = specificationMapList[key];
            currentSpecificationIdList.push(value.list[value.currentIndex].id);
        }

        this._currentSku = this._skuList.find(sku => {
            let skuSpecificationIdList = sku.goodsSpecificationIds.split("_").map(item => Number(item));

            let isOk = true;
            currentSpecificationIdList.forEach(currentSpecificationId => {
                if (!skuSpecificationIdList.includes(currentSpecificationId)) {
                    isOk = false;
                }
            })

            return isOk;
        })
    }

    @action
    async fetchRecommendList() {
        let {list} = await Api.recommendList({pageSize: 12, pageNum: 1});

        list = list.splice(0, 12);

        this._recommendList = list;
    }

    @action
    async fetchLookAroundList() {
        let {list} = await Api.recommendList({pageSize: 15, pageNum: 1});

        list = list.splice(0, 15);

        this._lookAroundList = list;
    }

    @computed get imgList() {

        let imgList = [];

        /*
        * 填充sku的图片列表
        * */
        if (this._currentSku?.skuPicList?.length) {
            imgList = [...this._currentSku.skuPicList?.split(";")];
        }

        /*
        * 填充商品的图片列表
        * */
        if (this._goods?.imgList?.length) {
            imgList = [...imgList, ...this._goods.imgList.split(";")];
        }
        if (this._goods?.mainPic) {
            imgList = [...imgList, this._goods.mainPic]
        }

        return imgList;
    }


    @action
    init() {
        this._goods = null;
        this._currentSku = null;
        this._hasCollect = false;
        this._collectId = 0;
        this._goodsAttributeList = [];
        this._specificationMapList = [];
        this._count = 1;
        this._recommendList = [];
        this._lookAroundList = [];
        this._promotionUrl = "";
        this._description = "";
        this._isSecKill = false;
        this._secKill = {
            hour: 0,
            minute: 0,
            second: 0
        }
        this._loading = false;
    }

    get goods() {
        return this._goods;
    }

    set goods(value) {
        this._goods = value;
    }

    get skuList() {
        return this._skuList;
    }

    set skuList(value) {
        this._skuList = value;
    }

    get currentSku() {
        return this._currentSku;
    }

    set currentSku(value) {
        this._currentSku = value;
    }

    get hasCollect() {
        return this._hasCollect;
    }

    set hasCollect(value) {
        this._hasCollect = value;
    }

    get collectId() {
        return this._collectId;
    }

    set collectId(value) {
        this._collectId = value;
    }

    get goodsAttributeList() {
        return this._goodsAttributeList;
    }

    set goodsAttributeList(value) {
        this._goodsAttributeList = value;
    }

    get specificationMapList() {
        return this._specificationMapList;
    }

    set specificationMapList(value) {
        this._specificationMapList = value;
    }

    get count() {
        return this._count;
    }

    set count(value) {
        this._count = value;
    }

    get recommendList() {
        return this._recommendList;
    }

    set recommendList(value) {
        this._recommendList = value;
    }

    get lookAroundList() {
        return this._lookAroundList;
    }

    set lookAroundList(value) {
        this._lookAroundList = value;
    }

    get promotionUrl() {
        return this._promotionUrl;
    }

    set promotionUrl(value) {
        this._promotionUrl = value;
    }

    get description() {
        return this._description;
    }

    set description(value) {
        this._description = value;
    }

    get isSecKill() {
        return this._isSecKill;
    }

    set isSecKill(value) {
        this._isSecKill = value;
    }

    get secKill() {
        return this._secKill;
    }

    set secKill(value) {
        this._secKill = value;
    }

    get loading() {
        return this._loading;
    }

    set loading(value) {
        this._loading = value;
    }
}

export default new GoodsInfoStore();
import {
  http,
  Header,
  ContentType,
  qs,
  GetFormData,
  StatusCode,
  Global,
  Method
} from './api-util';
import ImgEnum from 'enum/ImgEnum';

/**
 * 图片列表
 * @param {*} param0
 */
export const imgList = async ({ type }) => {
  const { data } = await http({
    method: Method.POST,
    url: Global.BASE_URL + '/web/img/list',
    data: {
      page: 1,
      size: 1000000,
      type
    }
  });

  const list = data.msg.objectList;

  return { list };
};

/**
 * 图片信息
 */
export const imgInfo = async ({ type }) => {
  const { data } = await http({
    method: Method.POST,
    url: Global.BASE_URL + '/web/img/list',
    data: {
      page: 1,
      size: 1,
      type
    }
  });

  const info = data.msg.objectList[0] || null;

  return { info };
};

import {observable, action, computed} from "mobx";
import * as Api from 'api/api';
import cookie from 'react-cookies';

class User {
    @observable _id = 0;
    @observable _username = "";
    @observable _avatar = "";
    @observable _nickname = "";
    @observable _gender = null;
    @observable _birthday = 0;
    @observable _token = "";

    @computed get isLogin() {
        return !!this.token;
    }

    @action setInfo(info) {
        const {id, username, avatar, nickname, gender, birthday} = info;

        this._id = id;
        this._username = username;
        this._avatar = avatar;
        this._nickname = nickname;
        this._gender = gender;
        this._birthday = birthday;
    }

    @action
    async login() {
        this.token = 'token';
        await this.fetchInfo();
    }

    @action logout() {
        this.token = '';
        this._username = "";
        this._avatar = "";
        this._nickname = "";
        this._gender = null;
        this._birthday = 0;
    }

    @action
    async fetchInfo() {
        const {info} = await Api.userInfo();

        this._id = info.id;
        this._username = info.username;
        this._avatar = info.avatar;
        this._nickname = info.nickname;
        this._gender = info.gender;
        this._birthday = info.birthday;
    }

    @action
    load() {
        this._token = cookie.load('token');
    }

    get username() {
        return this._username;
    }

    set username(value) {
        this._username = value;
    }

    get avatar() {
        return this._avatar;
    }

    set avatar(value) {
        this._avatar = value;
    }

    get nickname() {
        return this._nickname;
    }

    set nickname(value) {
        this._nickname = value;
    }

    get gender() {
        return this._gender;
    }

    set gender(value) {
        this._gender = value;
    }

    get birthday() {
        return this._birthday;
    }

    set birthday(value) {
        this._birthday = value;
    }

    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    get token() {
        return this._token;
    }

    set token(value) {
        this._token = value;
        cookie.save('token', value, {path: '/', maxAge: 60 * 60 * 2})
    }
}

const user = new User();

export default user;

import {
  http,
  Header,
  ContentType,
  qs,
  GetFormData,
  StatusCode,
  Global,
  Method
} from './api-util';

import ImgEnum from 'enum/ImgEnum';

export const homeBigBanner = async ({ pageSize, pageNum }) => {
  const { data } = await http({
    method: Method.POST,
    url: Global.BASE_URL + '/web/img/list',
    data: {
      page: pageNum,
      size: pageSize,
      type: ImgEnum.INDEX_IMG
    }
  });

  const list = data.msg.objectList;

  return { list };
};

import {observable, action, computed} from 'mobx';
import * as Api from 'api/api';

const provinceList = [{id: 0, name: '省份'}];
const cityList = [{id: 0, name: '城市'}];
const districtList = [{id: 0, name: '国家'}];

class Company2 {
    @observable _name = '';
    @observable _code = '';
    @observable _person = '';
    @observable _idNumber = '';
    @observable _idPictureList = [];
    @observable _provinceId = 0;
    @observable _cityId = 0;
    @observable _districtId = 0;
    @observable _detailedAddress = '';
    @observable _createDate = null;
    @observable _termStart = null;
    @observable _termEnd = null;
    @observable _termEndIsInfinite = false;
    @observable _registeredCapital = '';
    @observable _scope = '';
    @observable _license = null;
    @observable _licenseUrl = '';
    @observable _phone = '';
    @observable _emergencyPerson = '';
    @observable _emergencyPhone = '';
    @observable _provinceList = provinceList;
    @observable _cityList = cityList;
    @observable _districtList = districtList;

    get name() {
        return this._name;
    }

    set name(name) {
        this._name = name;
    }

    get code() {
        return this._code;
    }

    set code(code) {
        this._code = code;
    }

    get person() {
        return this._person;
    }

    set person(person) {
        this._person = person;
    }

    get idNumber() {
        return this._idNumber;
    }

    set idNumber(idNumber) {
        this._idNumber = idNumber;
    }

    get idPictureList() {
        return this._idPictureList;
    }

    set idPictureList(value) {
        this._idPictureList = value;
    }

    get provinceId() {
        return this._provinceId;
    }

    set provinceId(provinceId) {
        this._provinceId = provinceId;
    }

    get cityId() {
        return this._cityId;
    }

    set cityId(cityId) {
        this._cityId = cityId;
    }

    get districtId() {
        return this._districtId;
    }

    set districtId(districtId) {
        this._districtId = districtId;
    }

    get detailedAddress() {
        return this._detailedAddress;
    }

    set detailedAddress(detailedAddress) {
        this._detailedAddress = detailedAddress;
    }

    get createDate() {
        return this._createDate;
    }

    set createDate(createDate) {
        this._createDate = createDate;
    }

    @computed get createDateData() {
        if (!this._createDate) {
            return undefined;
        } else {
            return this._createDate.valueOf();
        }
    }

    get termStart() {
        return this._termStart;
    }

    set termStart(termStart) {
        this._termStart = termStart;
    }

    @computed get termStartData() {
        if (!this._termStart) {
            return undefined;
        } else {
            return this._termStart.valueOf();
        }
    }

    get termEnd() {
        return this._termEnd;
    }

    set termEnd(termEnd) {
        this._termEnd = termEnd;
    }

    @computed get termEndData() {
        if (this._termEndIsInfinite) {
            return -1;
        } else if (!this._termEnd) {
            return undefined;
        } else {
            return this._termEnd.valueOf();
        }
    }

    get termEndIsInfinite() {
        return this._termEndIsInfinite;
    }

    set termEndIsInfinite(termEndIsInfinite) {
        this._termEndIsInfinite = termEndIsInfinite;
    }

    get registeredCapital() {
        return this._registeredCapital;
    }

    set registeredCapital(registeredCapital) {
        this._registeredCapital = registeredCapital;
    }

    get scope() {
        return this._scope;
    }

    set scope(scope) {
        this._scope = scope;
    }

    get license() {
        return this._license;
    }

    set license(license) {
        this._license = license;
    }

    @computed get licenseData() {
        if (this._license) {
            return [this._license];
        } else {
            return undefined;
        }
    }

    get licenseUrl() {
        return this._licenseUrl;
    }

    set licenseUrl(licenseUrl) {
        this._licenseUrl = licenseUrl;
    }

    get phone() {
        return this._phone;
    }

    set phone(phone) {
        this._phone = phone;
    }

    get emergencyPerson() {
        return this._emergencyPerson;
    }

    set emergencyPerson(emergencyPerson) {
        this._emergencyPerson = emergencyPerson;
    }

    get emergencyPhone() {
        return this._emergencyPhone;
    }

    set emergencyPhone(emergencyPhone) {
        this._emergencyPhone = emergencyPhone;
    }

    get provinceList() {
        return this._provinceList;
    }

    set provinceList(provinceList) {
        this._provinceList = provinceList;
    }

    get cityList() {
        return this._cityList;
    }

    set cityList(cityList) {
        this._cityList = cityList;
    }

    get districtList() {
        return this._districtList;
    }

    set districtList(districtList) {
        this._districtList = districtList;
    }

    @action fetchProvinceList() {
        Api.provinceList().then(list => {
            this._provinceList = list.map(item => {
                const {id, name} = item;

                return {id, name};
            });
            this._cityList = cityList;
            this._districtList = districtList;
            this._cityId = 0;
            this._districtId = 0;
        });
    }

    @action fetchCityList({parentId}) {
        Api.areaList({parentId}).then(list => {
            this._cityList = list.map(item => {
                const {id, name} = item;
                return {id, name};
            });
            this._districtList = districtList;
            this._districtId = 0;
        });
    }

    @action fetchDistrictList({parentId}) {
        Api.areaList({parentId}).then(list => {
            this._districtList = list.map(item => {
                const {id, name} = item;
                return {id, name};
            });
        });
    }
}

let company2 = new Company2();

export default company2;

import {action, observable} from "mobx";
import MerchantType from "enum/MerchantType";
import {setDescSort, setLgstSort, setServSort} from "store/shop-street/action";

class ShopStreetStore {
    @observable _list = [];
    @observable _keyword = '';
    @observable _pageSize = 24;
    @observable _pageNum = 1;
    @observable _totalNum = 0;
    @observable _type = MerchantType.All.value;
    @observable _descSort = null;
    @observable _servSort = null;
    @observable _lgstSort = null;
    @observable _loading = false;

    get list() {
        return this._list;
    }

    set list(value) {
        this._list = value;
    }

    get keyword() {
        return this._keyword;
    }

    set keyword(value) {
        this._keyword = value;
    }

    get pageSize() {
        return this._pageSize;
    }

    set pageSize(value) {
        this._pageSize = value;
    }

    get pageNum() {
        return this._pageNum;
    }

    set pageNum(value) {
        this._pageNum = value;
    }

    get totalNum() {
        return this._totalNum;
    }

    set totalNum(value) {
        this._totalNum = value;
    }

    get type() {
        return this._type;
    }

    set type(value) {
        this._type = value;
    }

    get descSort() {
        return this._descSort;
    }

    set descSort(value) {
        this._descSort = value;
    }

    get servSort() {
        return this._servSort;
    }

    set servSort(value) {
        this._servSort = value;
    }

    get lgstSort() {
        return this._lgstSort;
    }

    set lgstSort(value) {
        this._lgstSort = value;
    }

    get loading() {
        return this._loading;
    }

    set loading(value) {
        this._loading = value;
    }

    @action
    resetSort() {
        this.descSort = null;
        this.servSort = null;
        this.lgstSort = null;
    };
}

export default new ShopStreetStore();
import type from './action-type';
import * as Api from 'api/api';

export const init = payload => {
  return {
    type: type.INIT,
    payload
  };
};

export const setList = payload => {
  return {
    type: type.SET_LIST,
    payload
  };
};

export const setPageSize = payload => {
  return {
    type: type.SET_PAGE_SIZE,
    payload
  };
};

export const setPageNum = payload => {
  return {
    type: type.SET_PAGE_NUM,
    payload
  };
};

export const setTotalNum = payload => {
  return {
    type: type.SET_TOTAL_NUM,
    payload
  };
};

// 获取列表
export const fetchList = ({
  pageNum,
  pageSize,
  type,
  descSort,
  servSort,
  lgstSort
}) => {

};

export const setKeyword = payload => {
  return {
    type: type.SET_KEYWORD,
    payload
  };
};

export const setType = payload => {
  return {
    type: type.SET_TYPE,
    payload
  };
};

export const setDescSort = payload => {
  return {
    type: type.SET_DESC_SORT,
    payload
  };
};

export const setServSort = payload => {
  return {
    type: type.SET_SERV_SORT,
    payload
  };
};

export const setLgstSort = payload => {
  return {
    type: type.SET_LGST_SORT,
    payload
  };
};

export const resetSort = () => {
  return f => {
    f(setDescSort(null));
    f(setServSort(null));
    f(setLgstSort(null));
  };
};

import { observable, action } from 'mobx';
import * as Api from 'api/api';

class Adv {
  @observable _topAdv = '';
  @observable _centerAdv = '';
  @observable _bottomAdv = undefined;

  get topAdv() {
    return this._topAdv;
  }
  set topAdv(value) {
    this._topAdv = value;
  }
  get centerAdv() {
    return this._centerAdv;
  }
  set centerAdv(value) {
    this._centerAdv = value;
  }
  get bottomAdv() {
    return this._bottomAdv;
  }
  set bottomAdv(value) {
    this._bottomAdv = value;
  }

  @action async fetchTopAdv() {
    try {
      const { info } = await Api.topAdv();
      this._topAdv = info;
    } catch (error) {}
  }

  @action async fetchCenterAdv() {
    try {
      const { info } = await Api.centerAdv();
      this._centerAdv = info;
    } catch (error) {}
  }

  @action async fetchBottomAdv() {
    try {
      const { info } = await Api.bottomAdv();
      this._bottomAdv = info;
    } catch (error) {}
  }

//   @action async fetchData() {
//     try {
//       this.fetchTopAdv();
//       this.fetchCenterAdv();
//       this.fetchBottomAdv();
//     } catch (error) {}
//   }
}

export default new Adv();

import { observable, action } from 'mobx';
import * as Api from 'api/api';
class Seo {
  @observable _title = '';
  @observable _keyword = '';
  @observable _description = '';

  get title() {
    return this._title;
  }
  set title(value) {
    this._title = value;
  }
  get keyword() {
    return this._keyword;
  }
  set keyword(value) {
    this._keyword = value;
  }
  get description() {
    return this._description;
  }
  set description(value) {
    this._description = value;
  }

  @action async fetchSeo() {
    try {
      const { info } = await Api.seoInfo();
      const { title, keyword, description } = info;

      this._title = title;
      this._keyword = keyword;
      this._description = description;
    } catch (error) {}
  }
}

export default new Seo();

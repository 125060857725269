import { observable, action } from "mobx";
import * as Api from "api/api";

class Help {
  @observable _menuList = [];
  @observable _info = null;

  get menuList() {
    return this._menuList;
  }
  set menuList(value) {
    this._menuList = value;
  }
  get info() {
    return this._info;
  }
  set info(value) {
    this._info = value;
  }

  @action async fetchMenuList(categoryId, articleId) {
    try {
      let { list } = await Api.helpMenuList();

      //类别放在前面，文章放在后面
      let menuList = [];
      list.forEach((item) => {
        if (!item.article) {
          menuList.push(item);
        }
      });
      list.forEach((item) => {
        if (item.article) {
          menuList.push(item);
        }
      });

      let hasInfo = false;
      if (categoryId || articleId) {
        this._menuList = menuList.map((item) => {
          let {
            id,
            title,
            name,
            article: isArticle,
            articles: articleList,
          } = item;

          let fatherActive = false;
          let showChild = false;

          if (!hasInfo) {
            if (!isArticle) {
              if (categoryId && categoryId === id) {
                fatherActive = true;
                showChild = true;
                hasInfo = true;

                if (articleList) {
                  articleList = articleList.map((item, index) => {
                    let active = false;

                    if (articleId) {
                      active = articleId === item.id;
                    } else {
                      if (index === 0) {
                        active = true;
                      }
                    }

                    if (active) {
                      this.fetchInfo(item.id);
                    }

                    return {
                      ...item,
                      active,
                    };
                  });
                }
              }
            } else {
              if (articleId === id) {
                fatherActive = true;
                hasInfo = true;
                this.fetchInfo(articleId);
              }
            }
          }

          return {
            id,
            title: title || name,
            isArticle,
            articleList,
            active: fatherActive,
            showChild,
          };
        });
      } else {
        this._menuList = menuList.map((item) => {
          let {
            id,
            title,
            name,
            article: isArticle,
            articles: articleList,
          } = item;

          let fatherActive = false;
          let showChild = false;
          let firstChildActive = false;

          if (!hasInfo) {
            if (!isArticle && articleList && articleList.length > 0) {
              this.fetchInfo(articleList[0].id);
              showChild = true;
              firstChildActive = true;
            } else {
              this.fetchInfo(id);
            }

            hasInfo = true;
            fatherActive = true;
          }

          if (articleList) {
            articleList = articleList.map((item, index) => {
              let childActive = false;

              if (index === 0) {
                childActive = firstChildActive;
              }

              return {
                ...item,
                active: childActive,
              };
            });
          }

          return {
            id,
            title: title || name,
            isArticle,
            articleList,
            active: fatherActive,
            showChild,
          };
        });
      }
    } catch (error) {}
  }

  @action async fetchInfo(id) {
    try {
      const { info } = await Api.helpInfo({ id });

      //   const { id, title, content, preArticle, nextArticle } = info;

      this._info = info;
    } catch (error) {}
  }

  @action async selectMenu({ fatherIndex, childIndex }) {
    this._menuList.forEach((father, i) => {
      if (fatherIndex !== i) {
        father.active = false;
      } else {
        father.active = true;
      }

      if (father.articleList) {
        father.articleList.forEach((child, j) => {
          if (fatherIndex === i && childIndex === j) {
            child.active = true;
          } else {
            child.active = false;
          }
        });
      }
    });
  }
}

const help = new Help();

export default help;
